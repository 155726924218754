header {
    display: flex;
    justify-content: space-between;
    .log {
        padding: 15px;
        .logIcon{
            width: 30px;
            height: 30px;
            color: whitesmoke;
            cursor: pointer;
            transition: 0.4s;
        }
        .logIcon:hover{
            color: rgba(245, 245, 245, 0.573);
        }
    }
    
    
        .tab {
            padding: 15px;
            font-size: 18px;
            a { 
                color: whitesmoke;
                text-decoration: none;
                text-decoration: none; 
                border-bottom: 2px solid transparent; 
                text-decoration: none; 
                position: relative; 
                cursor: pointer; 
              } 
               
            a::after { 
                content: ""; 
                position: absolute; 
                width: 100%; 
                height: 2px; 
                left: 0; 
                bottom: -2px; 
                background: whitesmoke; 
                transform: scale(0, 1); 
                transform-origin: 0% 100%; 
                transition: transform 0.3s ease; 
              } 
               
            a:hover::after { 
               transform: scale(1, 1); 
              }
        }
}

