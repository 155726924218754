
.boxLoginPage {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    margin-top: 130px;
    .box-log {
        width: 25%;
        background-color: #313233;
        border: 2px solid #e2b146;
        padding: 18px;
        height: 45%;
        border-radius: 11px;
    }
    
    
    .box-log {
        h2{
            color: whitesmoke;
            text-align: center;
        }
    }
    
    .log-panel{
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: transparent;
        border-radius: 8px;
        margin-top: 50px;
        margin-bottom: 40px !important;
        width: 100%;
        .log-container {
            background-color: #555 !important;
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            border-radius: 6px;
            margin-bottom: 10px !important;
        }
    }
    .button-panel {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: transparent;
        border-radius: 8px;
        margin-top: 50px;
        margin-bottom: 40px !important;
        width: 100%;
    }
    
    .Input {
        border: none;
        padding: 14px;
        padding-left: 46px; /* Добавлен отступ слева */
        width: 100%;
        background-color: #555 !important;
        border-radius: 6px;
        color: whitesmoke;
    }
    
    .Input:focus {
        outline: none;
        background-color: #777;
        box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.2);
    }
    .Input::-webkit-log-cancel-button {
        display: none;
    }
    
    .log-icon {
        position: absolute;
        left: 10px;
        font-size: 20px;
        color: whitesmoke;
    }
    
    .log-button {
        padding: 8px 15px;
        background-color: #749c85;;
        border: none;
        border-radius: 7px;
        cursor: pointer;
        width: 40%;
    }
    
    .log-button{
        color: rgb(237, 234, 234);
    }
    
    .log-button:hover {
        background-color: #567564;
    }
    
    .button-panel {
        margin-top: 66px;
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: row;
    }
}
