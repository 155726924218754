.mainContent {
    display: flex;
    // justify-content: center;
    // align-items: center;
    // align-content: center;
    flex-direction: column;
    margin: 0 auto;
    overflow: auto;
    height: 80vh;
    width: 90vw;
    table {
        overflow: auto; // Это необходимо для корректного отображения border-radius у вложенных элементов
    }
    td{
        height: 60px !important;
        
        overflow: hidden;
        // padding: 0;
    }
    .box {
        padding: 15px;
        margin: 5px;
        border: 1px solid whitesmoke;
        border-radius: 15px;
        .topBox {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 20px;
            width: 100%;
            height: 30px;
            input {
                width: 48%;
                border-radius: 10px;
            }
        }
        .downBox {
            min-height: 30px;
            width: 100%;
            input{
                width: 100%;
                border-radius: 10px;
                padding: 0 10px;
            }
        }
    }

}

.button-box {
    button {
        width: 20%;
        background-color: rgb(44, 39, 119);
        color: whitesmoke;
        border-radius: 25px;
        height: 50px;
    }
}